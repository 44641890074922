import { createContext, useState } from 'react'
import jwt from 'jwt-decode'
import * as browserStorage from '../utils/browserStorage'
import { Token } from 'src/jwtDecodeType'
import {
  getDarkModeEnabled,
  getTimeZone,
  setDarkModeEnabled,
  setTimeZone,
} from 'src/utils/helpers'

interface contextInterface {
  token: string | null
  isLoggedIn: boolean
  firstname: string | null
  lastname: string | null
  changeFirstname: (firstname: string) => void
  changeLastname: (lastname: string) => void
  login: (token: string) => void
  logout: () => void
  darkModeEnabledContext: boolean | undefined
  changeDarkModeEnabled: (value: any) => void
  timeZoneContext: string | undefined | null
  changeTimeZone: (value: any) => void
}

export const AuthContext = createContext<contextInterface>({
  token: '',
  isLoggedIn: false,
  firstname: '',
  lastname: '',
  login: (token: string) => {},
  logout: () => {},
  changeLastname: () => {},
  changeFirstname: () => {},
  darkModeEnabledContext: undefined,
  changeDarkModeEnabled: () => {},
  timeZoneContext: undefined,
  changeTimeZone: () => {},
})

//get token from localStorage
const userTokenStorage = browserStorage.getToken()

export const AuthContextProvider = (props: any) => {
  const [token, setToken] = useState(userTokenStorage)
  const [firstnameContext, setFirstnameContext] = useState(
    browserStorage.getFirstname(),
  )
  const [lastnameContext, setLastnameContext] = useState(
    browserStorage.getLastname(),
  )

  const [darkModeEnabledContext, setDarkModeEnabledContext] = useState(
    getDarkModeEnabled(),
  )
  const [timeZoneContext, setTimeZoneContext] = useState(getTimeZone())

  const userIsLoggedIn = !!token
  const handleLogin = (token: string) => {
    //browserStorage.setToken(token);
    browserStorage.setFirstname(jwt<Token>(token).firstname)
    setFirstnameContext(jwt<Token>(token).firstname)
    browserStorage.setLastname(jwt<Token>(token).lastname)
    setLastnameContext(jwt<Token>(token).lastname)
    setToken(token)
    setToken(token)
    setDarkModeEnabled(false)
    setDarkModeEnabledContext(false)
    setTimeZone('Europe/Paris')
    setTimeZoneContext('Europe/Paris')
  }

  const handleLogout = () => {
    setToken(null)
    setDarkModeEnabled(false)
    setDarkModeEnabledContext(false)
    browserStorage.clearStorage()
  }

  const handleChangeFirstname = (firstname: string) => {
    setFirstnameContext(firstname)
  }

  const handleChangeLastname = (lastname: string) => {
    setLastnameContext(lastname)
  }

  const handleChangeDarkModeEnabled = (darkMode: any) => {
    setDarkModeEnabledContext(darkMode)
  }

  const handleChangeTimezone = (timeZone: any) => {
    setTimeZoneContext(timeZone)
  }

  const contextValue = {
    token: token,
    isLoggedIn: userIsLoggedIn,
    firstname: firstnameContext,
    lastname: lastnameContext,
    darkModeEnabledContext: darkModeEnabledContext,
    timeZoneContext: timeZoneContext,
    changeFirstname: handleChangeFirstname,
    changeLastname: handleChangeLastname,
    changeDarkModeEnabled: handleChangeDarkModeEnabled,
    changeTimeZone: handleChangeTimezone,
    login: handleLogin,
    logout: handleLogout,
  }

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  )
}
