import { useContext } from 'react'
import {
  CDropdown,
  CDropdownDivider,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react'
import { cilLockLocked, cilSettings, cilUser } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from 'src/store/authContext'
import { clearStorage } from 'src/utils/browserStorage'
import { useDispatch, useSelector } from 'react-redux'
import { backdropShowActions } from 'src/store/features/backdrop/backdropShowSlice'
import style from './AppHeaderDropdownLanguage.module.css'
import { RouterContext } from 'src/store/RouterContext'
import { getAllowedRoutes } from 'src/utils/routes/getAllowedRoutes'
import { routes } from 'src/utils/routes/routes'
import AvatarInitial from 'src/components/avatar/AvatarInitial'

const AppHeaderDropdown = () => {
  //Traduction
  const { t } = useTranslation(['dropdown'])

  //Navigation init
  const navigate = useNavigate()
  const { handleUpdateRoutes } = useContext(RouterContext)

  //On récupère les données utilisateur dans le store contexte ainsi que la fonction de déconnexion
  const { logout, firstname, lastname } = useContext(AuthContext)

  const handlelogout = () => {
    //manageMercureSubscriptions([])
    logout()
    clearStorage()
    handleUpdateRoutes(getAllowedRoutes(routes))
    navigate('/login')
  }

  //Navigation sur la page de profil
  const handleClick = () => {
    navigate('/profile')
  }

  //Ajout d'un effet de blur au clique sur le dropdown en récupérant l'état du backdrop dans le store
  const dispatch = useDispatch()
  const backdropShow = useSelector(
    (state: any) => state.backdropShow.backdropShow
  )

  return (
    <CDropdown
      variant="nav-item"
      onClick={() => dispatch(backdropShowActions.show(!backdropShow))}
    >
      <CDropdownToggle className="py-0" caret={false}>
        {/* Avatar avec initiales utilisateur */}
        <AvatarInitial />
      </CDropdownToggle>
      <CDropdownMenu className={`${style.dropdown} dropdown-menu`}>
        <CDropdownItem>
          <div
            style={{ cursor: 'pointer' }}
            className="px-0"
            onClick={handleClick}
          >
            <CIcon icon={cilUser} className="me-2" />
            {t('avatarDropdown.profile')}
          </div>
        </CDropdownItem>
        <CDropdownItem>
          <div
            style={{ cursor: 'pointer' }}
            className="px-0"
            onClick={() => {
              navigate('/settings')
            }}
          >
            <CIcon icon={cilSettings} className="me-2" />
            {t('avatarDropdown.settings')}
          </div>
        </CDropdownItem>
        <CDropdownDivider />
        <CDropdownItem>
          <div style={{ cursor: 'pointer' }} onClick={handlelogout}>
            <CIcon icon={cilLockLocked} className="me-2" />
            {t('avatarDropdown.logout')}
          </div>
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  )
}

export default AppHeaderDropdown
