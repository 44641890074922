import { useSelector, useDispatch } from 'react-redux'
import { CSidebar, CSidebarBrand } from '@coreui/react'

//import logo from 'src/assets/logohc.png'
import style from './AppSidebar.module.css'

import { useTranslation } from 'react-i18next'

// sidebar nav config
import { Link } from 'react-router-dom'
import { sidebarActions } from 'src/store/features/sidebar/sidebarSlice'
import { StateInterface } from 'src/types/State'
import packageInfo from '../../../package.json'
import SidebarItemCategory from './sidebarMenu/SidebarItemCategory'
import SidebarItemCollapse from './sidebarMenu/SidebarItemCollapse'
import SidebarItem from './sidebarMenu/SidebarItem'
import { RouteInterface } from 'src/types/Route'
import { useContext } from 'react'
import { RouterContext } from 'src/store/RouterContext'

const AppSidebar = () => {
  const { t } = useTranslation([])
  const dispatch = useDispatch()
  const sidebarShow = useSelector(
    (state: StateInterface) => state.sidebar.sidebarShow,
  )
  const routes = useContext(RouterContext)

  //On filtre les éléments de navigations disponible suivant les roles

  return (
    <CSidebar
      position="fixed"
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        dispatch(sidebarActions.show(visible))
      }}
    >
      <CSidebarBrand className="d-none d-md-flex">
        <div className={style.logo}>
          <Link to="/">
            <h1>Rdynamik</h1>
            {/* <img className={style.img} src={logo} alt="hc conseil" /> */}
          </Link>
        </div>
      </CSidebarBrand>
      {/* <CSidebarNav>
        {filteredNavigation !== undefined ? (
          <AppSidebarNav items={filteredNavigation} />
        ) : (
          ''
        )}
      </CSidebarNav> */}
      <div style={{ overflow: 'auto', marginTop: '20px' }}>
        {/* PARTIE NAVIGATION */}
        {routes.routes!.map((route: RouteInterface, index: number) =>
          route.sidebarProps ? (
            route.sidebarProps.mainMenu ? (
              <SidebarItemCategory item={route} key={index} />
            ) : route.children ? (
              <SidebarItemCollapse item={route} key={index} />
            ) : (
              <SidebarItem item={route} key={index} />
            )
          ) : null,
        )}
      </div>
      <div
        style={{
          textAlign: 'center',
          position: 'absolute',
          bottom: '0px',
          backgroundColor: '#1e293a',
          zIndex: '99',
          width: '100%',
        }}
      >
        <span style={{ fontSize: '0.7rem' }}>
          LS2i &copy; 2023 {t('footer.rights')} - {packageInfo.version}
        </span>
      </div>
    </CSidebar>
  )
}

export default AppSidebar
