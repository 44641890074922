import jwtDecode from 'jwt-decode'
import { Token } from 'src/jwtDecodeType'
//Récupération des roles de l'utilisateur authentifié
export const getUserRoles = () => {
  const token = sessionStorage.getItem('rdynamikToken')
  if (token) {
    //décodage du jwt
    const userdatas = jwtDecode<Token>(token)
    return userdatas.roles
  }
}
