import {
  CAvatar,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react'
import { useDispatch, useSelector } from 'react-redux'
import { withTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import style from 'src/layout/protected/header/AppHeaderDropdownLanguage.module.css'
import fr from 'src/assets/images/fr.png'
import uk from 'src/assets/images/uk.png'
import notFound from 'src/assets/images/notFound.png'
import { setLanguage } from 'src/store/features/language/languageSlice'
import { backdropShowActions } from 'src/store/features/backdrop/backdropShowSlice'
import { StateInterface } from 'src/types/State'

const languagesProps: any = {
  fr: { image: fr, label: 'Français' },
  en: { image: uk, label: 'English' },
  // Ajoutez d'autres langues ici avec leurs chemins d'accès aux images
}

const AppHeaderDropdownLanguage = () => {
  //Ajout d'un effet de blur au clique sur le dropdown en récupérant l'état du backdrop dans le store
  const dispatch = useDispatch()
  const backdropShow = useSelector(
    (state: StateInterface) => state.backdropShow.backdropShow,
  ) //store du backdrop
  const language = useSelector(
    (state: StateInterface) => state.language.language,
  ) // store du langage

  const handleChangeLanguage = (lng: string) => {
    i18n.changeLanguage(lng)
    dispatch(setLanguage(lng))
  }

  return (
    <CDropdown
      onClick={() => dispatch(backdropShowActions.show(!backdropShow))}
      variant="nav-item"
    >
      <CDropdownToggle className="py-0" caret={false}>
        <CAvatar
          shape="rounded-0"
          src={
            languagesProps[language]?.image
              ? languagesProps[language].image
              : notFound
          }
          //className={`avatar-xs`}
          className={`${style.shadow} avatar-xs`}
        />
      </CDropdownToggle>
      <CDropdownMenu className={`${style.dropdown} dropdown-menu`}>
        {languagesProps ? (
          Object.keys(languagesProps)
            ?.filter((l) => l !== language)
            .map((lang) => {
              return (
                <CDropdownItem
                  key={lang}
                  aria-label={lang}
                  className={style.cursorLink}
                  onClick={() => {
                    setTimeout(() => {
                      handleChangeLanguage(lang)
                    }, 200)
                  }}
                  data-testid={lang}
                >
                  <img
                    height={48}
                    width={48}
                    src={languagesProps[lang].image}
                    alt={lang}
                    className="me-2 avatar-xs"
                    // size="sm"
                    // shape="rounded-0"
                  />
                  {languagesProps[lang].label}
                </CDropdownItem>
              )
            })
        ) : (
          <></>
        )}
      </CDropdownMenu>
    </CDropdown>
  )
}

export default withTranslation()(AppHeaderDropdownLanguage)
