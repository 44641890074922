import React from 'react'
import CIcon from '@coreui/icons-react'
import { cilUser, cilStorage } from '@coreui/icons'
import { Suspense } from 'react'
import Loader from '../../components/loader/Loader'
import PageLayout from './PagesLayout'
// import PublicLayout from 'src/layout/public/PublicLayout'
// import CreateAccount from "src/views/public/createAccount/CreateAccount";

//Setting page
const Settings = React.lazy(() => import('src/views/settings/Settings'))

//Profile page
const Profile = React.lazy(() => import('src/views/profile/Profile'))
const RakutenBlacklist = React.lazy(
  () => import('src/views/Rakuten/blacklist/RakutenBlacklist'),
)
const RakutenAmazonPrice = React.lazy(
  () => import('src/views/Rakuten/Settings/AmazonPrice/RakutenAmazonPrice'),
)
const RakutenFnacPrice = React.lazy(
  () => import('src/views/Rakuten/Settings/FnacPrice/RakutenFnacPrice'),
)

//Routes admin
const Users = React.lazy(() => import('src/views/admin/users/Users'))

export const routes: any = [
  // {
  //   name: 'Public',
  //   path: '/',
  //   element: <PublicLayout />,
  //   children: [
  //     {
  //       name: 'Login',
  //       index: true,
  //       element: <Login />,
  //       //On redirige l'utilisateur s'il est connecté
  //       loader: redirectIfUserIsLogged,
  //     },
  //     {
  //       name: 'Login',
  //       path: '/login',
  //       element: (
  //         <Suspense fallback={<Loader />}>
  //           <Login />
  //         </Suspense>
  //       ),
  //       loader: redirectIfUserIsLogged,
  //     },
  //     {
  //       name: 'Offline',
  //       path: '/offline',
  //       element: (
  //         <Suspense fallback={<Loader />}>
  //           <Offline />
  //         </Suspense>
  //       ),
  //       loader: redirectIfUserIsLogged,
  //     },
  //     {
  //       name: 'Recovery',
  //       path: '/recovery',
  //       element: (
  //         <Suspense fallback={<Loader />}>
  //           <PasswordRecovery />
  //         </Suspense>
  //       ),
  //       loader: redirectIfUserIsLogged,
  //     },
  //     //Page de login lorsque l'utilisateur a fait une demande de changement d'email et a cliqué sur le lien envoyé sur son ancien email. Si l'utilisateur connecté entre ce path, redirige vers la page de login
  //     {
  //       path: '/email/verify/:token',
  //       element: (
  //         <Suspense fallback={<Loader />}>
  //           <EmailChangeValidation />
  //         </Suspense>
  //       ),
  //       loader: redirectIfUserIsLogged,
  //     },
  //     //Page d'activation du compte pour un utilisateur non connecté qui a recu ce lien par email après avoir été créé par un administrateur
  //     {
  //       path: '/init/account/:token',
  //       element: (
  //         <Suspense fallback={<Loader />}>
  //           <CreateAccount />
  //         </Suspense>
  //       ),
  //       loader: redirectIfUserIsLogged,
  //     },
  //     //Page de modification du mot de passe, lien recu sur l'email de l'utilisateur après qu'il ait fait une demande de récupération de mot de passe
  //     {
  //       path: '/reset/password/:token',
  //       element: (
  //         <Suspense fallback={<Loader />}>
  //           <ResetForgotPassword />
  //         </Suspense>
  //       ),
  //       loader: redirectIfUserIsLogged,
  //     },
  //   ],
  // },
  // {
  //   name: 'Layout',
  //   path: '/',
  //   element: (
  //     <Suspense fallback={<Loader />}>
  //       <DefaultLayout />
  //     </Suspense>
  //   ),
  //   permissions: 'ROLE_USER',
  //children: [
  // {
  //   name: "ErrorPage",
  //   path: "*",
  //   element: <ErrorPage />,
  // },
  {
    name: 'Profile',
    path: '/profile',
    state: 'profile',
    element: (
      <Suspense fallback={<Loader />}>
        <Profile />
      </Suspense>
    ),
    //loader: loaderProfile,
    permissions: ['ROLE_USER'],
  },
  {
    name: 'Settings',
    path: '/settings',
    state: 'settings',
    element: (
      <Suspense fallback={<Loader />}>
        <Settings />
      </Suspense>
    ),
    permissions: 'ROLE_USER',
  },
  {
    name: 'rakutenAmazonPrice',
    index: true,
    state: 'rakuten.settings.amazon-price',
    element: (
      <Suspense fallback={<Loader />}>
        <RakutenAmazonPrice />
      </Suspense>
    ),
    permissions: 'ROLE_USER',
  },
  {
    name: 'Stock',
    sidebarProps: {
      displayText: 'stockManagement',
      mainMenu: true,
    },
    permissions: ['ROLE_USER'],
  },
  {
    name: 'Rakuten',
    path: '/rakuten',
    state: 'rakuten',
    sidebarProps: {
      displayText: 'stockRakuten',
      icon: (
        <CIcon
          icon={cilStorage}
          height={25}
          width={25}
          style={{ marginRight: '5px' }}
        />
      ),
      style: 'menu1',
    },
    element: <PageLayout />,
    permissions: ['ROLE_USER'],
    children: [
      {
        name: 'rakutenBlacklist',
        path: '/rakuten/blacklist',
        state: 'rakuten.blacklist',
        sidebarProps: {
          displayText: 'rakutenBlacklist',
          style: 'menu2',
        },
        element: (
          <Suspense fallback={<Loader />}>
            <RakutenBlacklist />
          </Suspense>
        ),
        permissions: ['ROLE_USER'],
      },

      {
        name: 'rakutenSettings',
        path: '/rakuten/settings',
        state: 'rakuten.settings',
        sidebarProps: {
          displayText: 'rakutenSettings',
          style: 'menu2',
        },
        element: <PageLayout />,
        permissions: 'ROLE_USER',
        children: [
          {
            name: 'rakutenAmazonPrice',
            path: '/rakuten/settings/amazon-price',
            state: 'rakuten.settings.amazon-price',
            sidebarProps: {
              displayText: 'rakutenAmazonPrice',
              style: 'menu3',
            },
            element: (
              <Suspense fallback={<Loader />}>
                <RakutenAmazonPrice />
              </Suspense>
            ),
            permissions: 'ROLE_USER',
          },
          {
            name: 'rakutenFnacPrice',
            path: '/rakuten/settings/fnac-price',
            state: 'rakuten.settings.fnac-price',
            sidebarProps: {
              displayText: 'rakutenFNACPrice',
              style: 'menu3',
            },
            element: (
              <Suspense fallback={<Loader />}>
                <RakutenFnacPrice />
              </Suspense>
            ),
            permissions: 'ROLE_USER',
          },
        ],
      },
    ],
  },
  {
    name: 'Admin',
    sidebarProps: {
      displayText: 'admin',
      mainMenu: true,
    },
    permissions: ['ROLE_ADMIN'],
  },

  {
    name: 'Users',
    path: '/admin/users',
    state: 'admin.users',
    sidebarProps: {
      displayText: 'users',
      icon: (
        <CIcon
          icon={cilUser}
          height={25}
          width={25}
          style={{ marginRight: '5px' }}
        />
      ),
    },
    element: (
      <Suspense fallback={<Loader />}>
        <Users />
      </Suspense>
    ),
    permissions: ['ROLE_ADMIN'],
    //   },
    // ],
  },
  //   ],
  // },
]
