import React, { useContext } from 'react'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CHeaderNav,
  CRow,
} from '@coreui/react'
import AppHeaderDropdownLanguage from 'src/layout/protected/header/AppHeaderDropdownLanguage'
import style from './PublicLayout.module.css'
import logo from 'src/assets/logo.png'
import { Outlet } from 'react-router-dom'
import { AnimationContext } from 'src/store/AnimationContext'

interface PublicLayoutProps {
  classNameAnimation?: string
}

const PubilcLayout: React.FC<PublicLayoutProps> = ({ classNameAnimation }) => {
  const { animationClassname } = useContext(AnimationContext)
  return (
    <div className={style.main}>
      <div className={style.context}>
        <CHeaderNav className="pt-3 me-3 justify-content-end">
          <AppHeaderDropdownLanguage />
        </CHeaderNav>
        <div className="min-vh-max d-flex flex-row align-items-center ">
          <CContainer>
            <CRow className="justify-content-center">
              <CCol sm={8} md={6} lg={4} xl={4} xs={12}>
                <CCardGroup className={animationClassname}>
                  <CCard>
                    <CCardBody className="p-4 text-center">
                      <h1>RDynamik</h1>
                      <Outlet />
                    </CCardBody>
                  </CCard>
                </CCardGroup>
              </CCol>
            </CRow>
          </CContainer>
        </div>
      </div>
    </div>
  )
}

export default PubilcLayout
