import jwtDecode from 'jwt-decode'
import { redirect } from 'react-router-dom'
import { Token } from 'src/jwtDecodeType'
import { getIsLoggedIn, getToken } from './browserStorage'

// //Fonction qui retourne le jwt stocké
// export const getJwt = (): string | null => {
//   return sessionStorage.getItem("JWT");
// };

//Fonction qui retourne la date d'expiration du jwt
export const getExp = (): number | null => {
  const jwt = getToken()
  if (typeof jwt === 'string') {
    return jwtDecode<Token>(jwt).exp
  } else return null
}

//Fonction qui retourne l'id de l'utilisateur courant
export const getUserId = (): number | null => {
  const jwt = getToken()
  if (typeof jwt === 'string') {
    return jwtDecode<Token>(jwt).id
  } else {
    return null
  }
}

//Fonction qui retourne le prénom d'utilisateur stocké dans le jwt
export const getUserFirstname = (): string | null => {
  const jwt = sessionStorage.getItem('JWT')
  if (jwt !== null) {
    return jwtDecode<Token>(jwt).firstname
  } else {
    return null
  }
}

//Fonction qui retourne le nom d'utilisateur stocké dans le jwt
export const getUserLastname = (): string | null => {
  const jwt = sessionStorage.getItem('JWT')
  if (jwt !== null) {
    return jwtDecode<Token>(jwt).lastname
  } else {
    return null
  }
}

/**
 * Fonction retournant les rôles de l'utilisateur
 * @returns tableau des rôles ou null
 */
export const getUserRoles = (): Array<string> | null => {
  const jwt = getToken()
  if (typeof jwt === 'string') {
    return jwtDecode<Token>(jwt).roles
  } else {
    return null
  }
}

export const getRole = () => {
  if (getUser()) {
    const roles = getUserRoles()
    if (roles !== null) {
      const role =
        roles.find((role) => role === 'ROLE_ADMIN') !== undefined
          ? 'ROLE_ADMIN'
          : 'ROLE_USER'
      return role
    } else {
      return null
    }
  } else {
    return null
  }
}

export const setUser = (user: string) => {
  sessionStorage.setItem('user', user)
}

export const getUser = () => {
  return sessionStorage.getItem('user')
}

/**
 * Fonction permettant de rediriger un utilisateur authentifié
 * @returns redirection ou null
 * //TODO: modifier les redirections
 */
export function redirectIfUserIsLogged() {
  // const isLogged = getIsLoggedIn()
  // if (isLogged === 'true') {
  const token = getToken()

  //Si l'utilisateur vérifie son email
  if (window.location.pathname.includes('/email/verify/:token')) {
    return
  }

  //Si l'utilisateur est authentifié, et a le rôle manager redirection vers les paramètres amazon
  if (token) {
    const userdatas: any = jwtDecode(token)
    const managerRole = ['ROLE_MANAGER']
    if (userdatas.roles.some((role: any) => managerRole.includes(role)))
      return redirect('/virtual-stock/rakuten/settings/fnac-price')
    else return redirect('/profile')
  }

  //la fonction doit avoir une valeur de retour si le token est présent. Ici nous n'avons pas besoin de valeur spécifique, le retour est null
  return null
}

//Retourne si le mode dark est activé dans le session storage
export const getDarkModeEnabled = () => {
  return sessionStorage.getItem('darkModeEnabled') === 'true' ? true : false
  // return sessionStorage.getItem("darkModeEnabled")
}

//Stocke si le mode dark est activé dans le session storage
export const setDarkModeEnabled = (darkMode: boolean) => {
  return sessionStorage.setItem('darkModeEnabled', darkMode.toString())
}

//Retourne la time zone choisie
export const getTimeZone = () => {
  return sessionStorage.getItem('timeZone')
  // return sessionStorage.getItem("darkModeEnabled")
}

////Stocke la time zone choisie
export const setTimeZone = (timeZone: string) => {
  return sessionStorage.setItem('timeZone', timeZone)
}
