import React from 'react'
import { redirectIfUserIsLogged } from 'src/utils/helpers'
import { Suspense } from 'react'
import Loader from 'src/components/loader/Loader'
//import PublicLayout from "src/layout/public/PublicLayout"

// Pages utilisateur non authentifié
const Login = React.lazy(() => import('src/views/public/login/Login'))
const CreateAccount = React.lazy(
  () => import('src/views/public/createAccount/CreateAccount'),
)
//Vérification email
const EmailChangeValidation = React.lazy(
  () => import('src/views/emailChangeValidation/EmailChangeValidation'),
)
const PasswordResetValidation = React.lazy(
  () =>
    import('src/views/public/passwordResetValidation/PasswordResetValidation'),
)
const Offline = React.lazy(() => import('src/views/public/offline/Offline'))
const PasswordReset = React.lazy(
  () => import('src/views/public/passwordReset/passwordReset'),
)

export const publicRoutes =
  // {
  //   name: "Public",
  //   path: "/",
  //   element: <PublicLayout />,
  //   children:
  [
    {
      name: 'Login',
      index: true,
      state: 'login',
      element: <Login verifyEmail={false} />,
      //On redirige l'utilisateur s'il est connecté
      loader: redirectIfUserIsLogged,
    },
    {
      name: 'Login',
      path: '/login',
      state: 'login',
      element: (
        <Suspense fallback={<Loader />}>
          <Login verifyEmail={false} />
        </Suspense>
      ),
      loader: redirectIfUserIsLogged,
    },
    {
      name: 'Offline',
      path: '/offline',
      element: (
        <Suspense fallback={<Loader />}>
          <Offline />
        </Suspense>
      ),
      loader: redirectIfUserIsLogged,
    },
    {
      name: 'Recovery',
      path: '/recovery',
      element: (
        <Suspense fallback={<Loader />}>
          <PasswordReset />
        </Suspense>
      ),
      loader: redirectIfUserIsLogged,
    },
    //Page de login lorsque l'utilisateur a fait une demande de changement d'email et a cliqué sur le lien envoyé sur son ancien email. Si l'utilisateur connecté entre ce path, redirige vers la page de login
    {
      path: '/email/verify/:token',
      element: (
        <Suspense fallback={<Loader />}>
          <EmailChangeValidation />
        </Suspense>
      ),
      //loader: redirectIfUserIsLogged,
    },
    //Page d'activation du compte pour un utilisateur non connecté qui a recu ce lien par email après avoir été créé par un administrateur
    {
      path: '/init/account/:token',
      element: (
        <Suspense fallback={<Loader />}>
          <CreateAccount />
        </Suspense>
      ),
      loader: redirectIfUserIsLogged,
    },
    //Page de modification du mot de passe, lien recu sur l'email de l'utilisateur après qu'il ait fait une demande de récupération de mot de passe
    {
      path: '/reset/password/:token',
      element: (
        <Suspense fallback={<Loader />}>
          <PasswordResetValidation />
        </Suspense>
      ),
      loader: redirectIfUserIsLogged,
    },
  ]
//}
