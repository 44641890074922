import { Suspense, useContext } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import { RouterContext } from './store/RouterContext'
import Loader from './components/loader/Loader'
import { getUserRoles } from './utils/helpers'
import PubilcLayout from './layout/public/PublicLayout'
import DefaultLayout from './layout/protected/DefaultLayout'
import ErrorPage from './views/ErrorPage/ErrorPage'
import { generateRoute } from './utils/routes/GenerateRoutes'

const App = () => {
  //ROUTER
  const routes = generateRoute(useContext(RouterContext).routes!)
  const roles = getUserRoles()
  return (
    <Suspense fallback={<Loader />}>
      <BrowserRouter>
        <Routes>
          {!roles ? (
            <Route path="/" element={<PubilcLayout />}>
              {routes}
            </Route>
          ) : (
            <Route path="/" element={<DefaultLayout />}>
              {routes}
            </Route>
          )}
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  )
}

export default App
