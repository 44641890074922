import { ReactNode, useContext, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { AuthContext } from 'src/store/authContext'
import { appStateActions } from 'src/store/features/appState/appStateSlice'

type Props = {
  state?: string
  children: ReactNode
}

const PageWrapper = (props: Props) => {
  //console.log("WRAPPER state: ", props.state)
  const dispatch = useDispatch()

  //récupération de la variable darkmode
  const { darkModeEnabledContext } = useContext(AuthContext) //Contexte application nom et prénom utilisateur si changement des infos via mercure

  useEffect(() => {
    if (props.state) {
      dispatch(appStateActions.set(props.state))
    }
    if (darkModeEnabledContext === true) {
      document.body.classList.add('dark-mode')
    } else {
      document.body.classList.remove('dark-mode')
    }
  }, [dispatch, darkModeEnabledContext, props])

  return <>{props.children}</>
}

export default PageWrapper
