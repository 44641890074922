const tokenItem = 'rdynamikToken'
const firstnameItem = 'rdynamikFirstname'
const lastnameItem = 'rdynamikLastname'
const payloadItem = 'rdynamikPayload'
const isLogged = 'isLogged'

export const getToken = () => {
  return sessionStorage.getItem(tokenItem)
}
export const setToken = (token: string) => {
  return sessionStorage.setItem(tokenItem, token)
}
export const removeToken = () => {
  return sessionStorage.removeItem(tokenItem)
}

export const getFirstname = () => {
  return sessionStorage.getItem(firstnameItem)
}
export const setFirstname = (firstname: string) => {
  return sessionStorage.setItem(firstnameItem, firstname)
}
export const removeFirstname = () => {
  return sessionStorage.removeItem(firstnameItem)
}

export const getLastname = () => {
  return sessionStorage.getItem(lastnameItem)
}
export const setLastname = (lastname: string) => {
  return sessionStorage.setItem(lastnameItem, lastname)
}
export const removeLastname = () => {
  return sessionStorage.removeItem(lastnameItem)
}

export const getPayload = () => {
  return sessionStorage.getItem(payloadItem)
}
export const setPayload = (payload: string) => {
  return sessionStorage.setItem(payloadItem, payload)
}
export const removePayload = () => {
  return sessionStorage.removeItem(payloadItem)
}

export const getIsLoggedIn = () => {
  return sessionStorage.getItem(isLogged)
}

export const setIsLoggedIn = (value: string) => {
  sessionStorage.setItem(isLogged, value)
}

//Nettoyer le sessionStorage lors de la déconnexion
//Excepté la clé de la langue
export const clearStorage = () => {
  const lng = sessionStorage.getItem('i18nextLng')
  sessionStorage.clear()
  if (lng) {
    sessionStorage.setItem('i18nextLng', lng)
  }
}
