import 'react-app-polyfill/stable'
import 'core-js'
import { createRoot } from 'react-dom/client'
import App from './App'
import './index.css'
import { Provider } from 'react-redux'
import { store } from './store/store'
import { I18nextProvider } from 'react-i18next'
import i18n from './i18n'
import 'primereact/resources/themes/lara-light-indigo/theme.css' //theme
import 'primereact/resources/primereact.min.css' //core
import 'primeicons/primeicons.css' //icons
import { AuthContextProvider } from './store/authContext'
import { RouterContextProvider } from './store/RouterContext'
import 'animate.css'
import { AnimationContextProvider } from './store/AnimationContext'

createRoot(document.getElementById('root')!).render(
  <AuthContextProvider>
    <RouterContextProvider>
      <AnimationContextProvider>
        <Provider store={store}>
          <I18nextProvider i18n={i18n}>
            <App />
          </I18nextProvider>
        </Provider>
      </AnimationContextProvider>
    </RouterContextProvider>
  </AuthContextProvider>
)
