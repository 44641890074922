import { ProgressSpinner } from "primereact/progressspinner";

const Loader = () => {
  return (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse">
        <ProgressSpinner
          animationDuration="1s"
          className="mx-1 main-spinner"
          style={{ width: "40px", height: "40px" }}
        />
      </div>
    </div>
  );
};

export default Loader;
