import { createContext, useState } from 'react'

export const AnimationContext = createContext({
  animationClassname: '',
  handleUpdateAnimationClassname: (animationClassname: string) => {},
})

export const AnimationContextProvider = (props: any) => {
  const [animationClassname, setAnimationClassname] = useState('')

  const handleUpdateAnimationClassname = (animationClassname: string) => {
    setAnimationClassname(animationClassname)
  }

  const contextValue = {
    animationClassname: animationClassname,
    handleUpdateAnimationClassname: handleUpdateAnimationClassname,
  }

  return (
    <AnimationContext.Provider value={contextValue}>
      {props.children}
    </AnimationContext.Provider>
  )
}
